<template>
  <app-page name="Политика конфиденциальности">
    <h1>Политика конфиденциальности</h1>
    <h1 class="section__title text-uppercase rules__title">Политика в отношении обработки персональных данных</h1>
    <h3>1. Общие положения</h3>
    <p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Общего регламента защиты персональных данных (General Data Protection Regulation (GDPR) и определяет порядок обработки персональных данных, меры по обеспечению безопасности персональных данных, предпринимаемые Новостной витриной (далее – Оператор).</p>
    <p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
    <p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях Новостной витрины - веб-сайт
      <a href="https://news-lab.club">news-lab.club</a></p>
    <h3>2. Основные понятия, используемые в Политике</h3>
    <p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.</p>
    <p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
    <p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу, указанному выше и именуемом Новостная витрина.</p>
    <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.</p>
    <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
    <p>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
    <p>2.7. Оператор – юридическое лицо MAKE PROFIT SRL, которое зарегистрировано и находится по адресу: Sector 5, Calea Rahovei Str. 266-268, CORP 2, AXELE A-B, office 06D, Bucharest, Romania, 050897, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
    <p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю Новостной витрины.</p>
    <p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, - персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее - персональные данные, разрешенные для распространения).</p>
    <p>2.10. Пользователь – любой посетитель Новостной витрины, прошедший /не прошедший регистрацию.</p>
    <p>2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
    <p>2.12. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно- телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
    <p>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>
    <h3>3. Основные права и обязанности субъектов персональных данных.</h3>
    <p>3.1. Субъекты персональных данных имеют право:</p>
    <p>– получать информацию, касающуюся обработки его персональных данных, перенести ее в новую службу, потребовать исправления, обновить или удалить. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных;</p>
    <p>– требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
    <p>– выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;</p>
    <p>– на отзыв согласия на обработку персональных данных;</p>
    <p>– обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных.</p>
    <p>3.2. Субъекты персональных данных обязаны:</p>
    <p>– предоставлять Оператору достоверные данные о себе;</p>
    <p>– сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</p>
    <p>3.3. Для того, чтобы воспользоваться вышеуказанными правами, Пользователю нужно связаться с Оператором, используя контактную информацию, указанную ниже:
      <a href="mailto:support@news-lab.club">support@news-lab.club</a></p>
    <h3>4. Когда Посетитель посещает Веб-сайт, Оператор собирает определенную информацию об устройстве Посетителя, такую как адрес интернет-протокола (IP), тип и версию браузера, местоположение и часовой пояс, язык, операционную систему и платформу доступа.</h3>
    <p>4.1. Также Оператор собирает информацию о взаимодействии Посетителя с Веб-сайтом и следующею информацию:</p>
    <p>- личные данные Посетителя: имя, фамилию, контактный телефон;</p>
    <p>- дополнительную информацию, если Посетитель обращается к Оператору в случае возникновения любых вопросов, которые касаются Веб-сайта.</p>
    <p>4.2. Информация, которая указана в п. 4.1., собирается Оператором в автоматическом режиме, как только Пользователь заходит на Веб-сайт, а также предоставляется Посетителем самостоятельно в процессе регистрации на Веб-сайте.</p>
    <p>4.3. Целью сбора информации, указанной в п. 4.1., является необходимостью для надлежащей работы и улучшения Веб-сайта Оператора</p>
    <p>4.4. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.</p>
    <p>4.5. Согласие на обработку персональных данных, разрешенных для распространения, Пользователь предоставляет Оператору непосредственно путем посещение Новостной витрины и отправке Оператору своих персональных данных (заполнение формы) при регистрации.</p>
    <p>4.6. Оператор оповещает данными Правилами, что Оператор получил указанное согласия Пользователя опубликовать информацию об условиях обработки, а также уведомил о наличии запретов и условий на обработку неограниченным кругом лиц персональных данных, разрешенных для распространения.</p>
    <p>4.7. Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом персональных данных для распространения, должна быть прекращена в любое время по требованию субъекта персональных данных. Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться только Оператором, которому оно направлено.</p>
    <p>4.8. Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента поступления Оператору требования, указанного в п. 4.7. настоящей Политики в отношении обработки персональных данных.</p>
    <h3>5. Принципы обработки персональных данных.</h3>
    <p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
    <p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
    <p>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</p>
    <p>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
    <p>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.</p>
    <p>5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.</p>
    <p>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством соответствующей страны.</p>
    <h3>6. Цели обработки персональных данных.</h3>
    <p>6.1. Цель обработки персональных данных Пользователя:</p>
    <p>- предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Новостной витрине.</p>
    <p>6.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях.
      Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты
      <a href="mailto:support@news-lab.club">support@news-lab.club</a> с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</p>
    <p>6.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на Веб-сайте, улучшения качества Веб-сайта и его содержания.</p>
    <h3>7. Правовые основания обработки персональных данных.</h3>
    <p>7.1. Правовыми основаниями обработки персональных данных Оператором являются:</p>
    <p>– Общей регламент защиты персональных данных (General Data Protection Regulation (GDPR);</p>
    <p>– согласия Пользователей на обработку их персональных данных, на обработку персональных данных, разрешенных для распространения.</p>
    <p>7.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).</p>
    <p>7.4. Субъект персональных данных самостоятельно принимает решение о предоставлении его персональных данных и дает согласие свободно, своей волей и в своем интересе.</p>
    <h3>8. Условия обработки персональных данных.</h3>
    <p>8.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.</p>
    <p>8.2. Обработка персональных данных необходима для достижения целей, предусмотренных данной Политикой, Общим регламентом защиты персональных данных (General Data Protection Regulation (GDPR).</p>
    <p>8.3. Обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.</p>
    <p>8.4. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее – общедоступные персональные данные).</p>
    <p>8.5. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с Общим регламентом защиты персональных данных (General Data Protection Regulation (GDPR).</p>
    <h3>9. Порядок сбора, хранения, передачи и других видов обработки персональных данных.</h3>
    <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
    <p>9.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
    <p>9.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</p>
    <p>9.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора support@news-lab.club с пометкой «Актуализация персональных данных».</p>
    <p>9.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен.</p>
    <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора
      <a href="mailto:support@news-lab.club">support@news-lab.club</a> с пометкой «Отзыв согласия на обработку персональных данных».</p>
    <p>9.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Правилами пользования Новостной витриной и этой Политикой конфиденциальности. Субъект персональных данных и/или Пользователь обязан самостоятельно своевременно ознакомиться с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.</p>
    <p>9.6. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.</p>
    <p>9.7. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, либо до момента пока Посетитель не попросит ее удалить.</p>
    <p>9.8. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.</p>
    <h3>10. Перечень действий, производимых Оператором с полученными персональными данными.</h3>
    <p>10.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
    <p>10.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>
    <h3>11. Данные Оператора.</h3>
    <p>11.1. Оператором согласно требованиям Общего регламента защиты персональных данных (General Data Protection Regulation (GDPR) является:</p>
    <p>- название юридического лица: Make Profit SRL;</p>
    <p>- адрес юридического лица: Sector 5, Calea Rahovei Str. 266-268, CORP 2, AXELE A-B, office 06D, Bucharest, Romania, 050897;</p>
    <p>- электронный адрес: <a href="mailto:support@news-lab.club">support@news-lab.club</a></p>
    <h3>12. Конфиденциальность персональных данных.</h3>
    <p>Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено Общим регламентом защиты персональных данных (General Data Protection Regulation (GDPR).</p>
    <h3>13. Внесение изменений в Политику.</h3>
    <p>13.1. Оператор может периодически обновляет данную Политику.</p>
    <p>13.2. Все изменения, которые будут вносится в данную Политику будут оперативно размещены на Веб-сайте.</p>
    <p>13.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://news-lab.club/privacy.html" target="_blank">https://news-lab.club/privacy</a>.</p>
    <h3>14. Способы защиты персональной информации.</h3>
    <p>14.1. Оператор предлагает соответствующие методы защиты, которые защищают персональную информацию Посетителя в целях защиты от уничтожения, потери, изменения, доступа, раскрытия и несанкционированного использования.</p>
    <p>14.2. Вся информация, которую Посетители предоставляют Оператору, хранится на защищенных серверах. Оператор использует современные методы и технологии шифрования информации, чтобы обеспечить большую безопасность и предотвратить возможное мошенничество. Для дополнительной безопасности платежные транзакции обрабатываются автоматически банками или платежными системами (в зависимости от выбранного метода оплаты). Таким образом, Оператор не хранит никаких личных или банковских данных, связанных с транзакцией Посетителя.</p>
    <p>14.3. Однако просим обратить внимание, что Оператор не может гарантировать конфиденциальность данных при любых обстоятельствах и навсегда, учитывая границы систем безопасности. Тем не менее, Оператор стремится обновлять систему безопасности, чтобы не нарушить конфиденциальность.</p>
    <h3>15. Заключительные положения.</h3>
    <p>15.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты
      <a href="mailto:support@news-lab.club">support@news-lab.club</a>.</p>
  </app-page>
</template>

<script>
import AppPage from "../components/AppPage";

export default {
  name: 'privacy',
  components: {
    AppPage
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;

  @media screen and (min-width: 1201px){
    display: none;
  }
}
h3, p, li {
  color: #4d4d4d;
}

ul, li, p {
  margin-top: 16px;
}

p, li {
  font-size: 14px;
  line-height: 24px;

  a {
    color: #000;
    text-decoration: underline;
  }
}

h3 {
  margin-top: 40px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

li {
  padding-left: 25px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    border: 2px solid #E94E1B;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>